/* --------------------------------------------------
 Typography
-------------------------------------------------- */
// h1,
// .h1 {
//   font-size: 28px;
// }

// h2,
// .h2 {
//   font-size: 28px;
// }

// h3,
// .h3 {
//   font-size: 24px;
// }

// h4,
// .h4 {
//   font-size: 22px;
// }

// h5,
// .h5 {
//   font-size: 18px;
// }

// h6,
// .h6 {
//   font-size: 16px;
// }
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-family: $gothic;
}

@media (min-width: 768px) and (max-width: 1024px) {
  p {
    font-size: 18px;
  }

  h4 {
    font-size: 28px;
  }
}

@media(min-width: 1025px) {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-family: $gothic;
  }

  h1,
  .h1 {
    /*font-size: 83px;*/
    font-size: 3.3vw;

    @media (max-width: 1920px) {
      font-size: 4.3vw;
    }
  }

  h2,
  .h2 {
    /*font-size: 88px;*/
    font-size: 3.6vw;

    @media (max-width: 1920px) {
      font-size: 4.6vw;
    }
  }

  h3,
  .h3 {
    /*font-size: 67px;*/
    font-size: 2.5vw;

    @media (max-width: 1920px) {
      font-size: 2.8vw;
    }

  }

  h4,
  .h4 {
    /*font-size: 42px;*/
    font-size: 1.2vw;

    @media (max-width: 1920px) {
      font-size: 1.76vw;
    }
  }

  h5,
  .h5 {
    /*font-size: 37px;*/
    font-size: 1vw;

    @media (max-width: 1920px) {
      font-size: 1.44vw;
    }
  }

  h6,
  .h6 {
    /*font-size: 29px;*/
    color: $white;
    font-size: 1vw;
    line-height: 1.72;

    // @media(max-width: 767px) {
    //   font-size: 16px;
    // }
    @media (max-width: 1920px) {
      font-size: 1.5vw;
    }
  }

  p {
    /*font-size: 25px;*/
    font-size: 1vw;
    line-height: 1.45;

    @media (max-width: 1920px) {
      font-size: 1.04vw;
    }

    &.large {
      /*font-size: 33px;*/
      font-size: 1.36vw;
    }

    &.small {
      /*font-size: 23px;*/
      font-size: 1.1vw;
    }
  }

  .large {
    p {
      @extend .large;
    }
  }

}