/* --------------------------------------------------
  Buttons styling
-------------------------------------------------- */
.btn {
  display: inline-block;
  padding: 6px 12px;
  background-image: none;
  font-size: 14px;
  text-align: center;

  &.btn-lg {
    padding: 20px 40px;
    font-size: 24px;
  }
}

.iwt-btn-wrapper {
  position: relative;
  display: inline-block;
  padding: 0 15px;

  @media (max-width: 991px) {
    width: 100%;

  }

  .btn-wrapper {
    display: block;

    &:hover {
      .btn-arrow {
        transform: scale(1.1);
        transition-duration: .4s;
        transition-timing-function: ease;
      }
    }
  }


  .btn-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 6vw;
    width: 6vw;
    margin: auto;
    border: 7px solid $button-border;
    border-radius: 50%;
    background-color: $separator-color;
    text-align: center;

    @media (max-width: 1920px) {
      height: 7vw;
      width: 7vw;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      height: 60px;
      width: 60px;
    }

    @media (max-width: 767px) {
      height: 85px;
      width: 85px;
    }

    @media (max-width: 374px) {
      height: 60px;
      width: 60px;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 35px;
      width: 35px;
      margin: auto;
      background-size: 100% auto;

      @media (min-width: 768px) and (max-width: 991px) {
        height: 25px;
        width: 25px;
      }

      @media (max-width: 374px) {
        height: 20px;
        width: 20px;
      }
    }


  }


  h5,
  .btn-content {
    margin-left: 20px;
    padding: 30px 2vw 26px 6vw;
    background-color: $text-title;
    color: $white;
    text-align: center;

    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 15px 2vw 15px 5vw;
    }

    @media (max-width: 767px) {
      margin-left: 35px;
      padding: 25px 25px 25px 50px;
    }

    @media (max-width: 374px) {
      padding: 16px 30px 16px 45px;
      font-size: 15px;
    }
  }

}