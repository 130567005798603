/* --------------------------------------------------
 Header Style
-------------------------------------------------- */

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  background-color: transparent;
  color: $white;
  transition: all 0.5s ease 0s;
  font-family: $gothic;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-family: $gothic;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    p {
      font-size: 18px;
    }

    h4 {
      font-size: 28px;
    }
  }

  @media(min-width: 1025px) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
      font-weight: bold;
      font-family: $gothic;
    }

    h1,
    .h1 {
      /*font-size: 83px;*/
      font-size: 3.3vw;

      @media (max-width: 1920px) {
        font-size: 4.3vw;
      }
    }

    h2,
    .h2 {
      /*font-size: 88px;*/
      font-size: 3.6vw;

      @media (max-width: 1920px) {
        font-size: 4.6vw;
      }
    }

    h3,
    .h3 {
      /*font-size: 67px;*/
      font-size: 2.5vw;

      @media (max-width: 1920px) {
        font-size: 3.5vw;
      }

    }

    h4,
    .h4 {
      /*font-size: 42px;*/
      font-size: 1.2vw;

      @media (max-width: 1920px) {
        font-size: 1.76vw;
      }
    }

    h5,
    .h5 {
      /*font-size: 37px;*/
      font-size: 1vw;

      @media (max-width: 1920px) {
        font-size: 1.8vw;
      }
    }

    h6,
    .h6 {
      /*font-size: 29px;*/
      color: $white;
      font-size: 1vw;
      line-height: 1.72;


      @media (max-width: 1920px) {
        font-size: 1.2vw;
      }
    }

    p {
      font-size: 1vw;
      line-height: 1.45;

      @media (max-width: 1920px) {
        font-size: 1.3vw;
      }

      &.large {
        font-size: 1.36vw;
      }

      &.small {
        font-size: 1.1vw;
      }
    }

    .large {
      p {
        @extend .large;
      }
    }

  }

  &.header_fixed {
    background-color: $black;
  }

  .container {
    max-width: 1600px !important;
    width: inherit !important;

    @media (max-width: 1920px) {
      max-width: 1600px !important;
      width: inherit !important;
    }

    @media(max-width: 992px) {
      max-width: 90vw !important;
      width: inherit !important;
    }

  }

  .navbar {
    padding: 0 45px;

    @media (max-width:1024px) {
      padding: 0 10px;
    }

    @media (max-width:991px) {
      padding: 0 15px;
    }

    .left-logo {
      display: none;

      @media (max-width:991px) {
        display: block;
      }

      img {
        @media (max-width: 767px) {
          max-width: 130px;
        }

        &.img-black {
          @media (max-width: 991px) {
            display: none;
          }
        }

        @media (max-width:991px) {
          max-width: 200px;
        }

        @media (max-width: 767px) {
          max-width: 180px;
        }

        @media (max-width: 384px) {
          max-width: 145px;
        }
      }
    }

    .navbar-toggler {
      border-radius: 0;
      outline: none;
      border: 0;

      @media (max-width: 767px) {
        padding: 2px;
      }

      .navbar-toggler-icon {
        background-color: $white;
        width: 35px;
        height: 3px;
        position: relative;
        display: block;
        cursor: pointer;

        @media (max-width: 767px) {
          width: 25px;
        }

        &::after,
        &::before {
          position: absolute;
          width: 35px;
          height: 3px;
          bottom: 13px;
          left: 0;
          content: " ";
          background-color: $white;

          @media (max-width: 767px) {
            width: 25px;
            bottom: 8px;
          }
        }

        &::before {
          top: 12px;

          @media (max-width: 767px) {
            top: 8px;
          }
        }
      }
    }

    .navbar-collapse {
      padding: 0;

      // Left - Right side
      .site-header-left,
      .site-header-right {
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
          display: block;
        }

        .fa {
          @extend .h6;
          line-height: normal;
        }

        .dropdown {
          display: block;

          @media (max-width: 991px) {
            display: none;
          }

          .dropdown-toggle {

            &:after {
              display: none;
            }
          }
        }
      }

      // Logo

      .logo {
        width: 17vw;
        display: flex;
        align-items: center;

        @media (min-width: 1600px) {
          margin-left: -200px;

        }

        @media (max-width: 1599px) {
          margin-left: -200px;
        }

        @media (max-width: 1440px) {
          margin-left: -180px;
        }

        @media (max-width:1360px) {
          margin-left: 0;
        }

        @media (max-width:1024px) {
          max-width: 260px;

        }

        .img-black {
          display: none;
        }

        @media (max-width:991px) {
          display: none;
        }
      }
    }

    // Menu link 
    .nav-item {
      padding: 32px 16px;

      @media (max-width:1200px) {
        padding: 0 8px;
      }

      @media (max-width:1024px) {
        padding: 0 12px;



        width: 100%;
      }

      &.active {
        .sub-menu {
          display: block;
        }
      }


      .nav-link {
        padding: 0;
        font-size: 1.2vw;
        text-transform: capitalize;
        letter-spacing: 1px;
        color: inherit;
        font-weight: bold;
        height: 100%;
        white-space: nowrap;

        &.nav-call,
        &.nav-mail {
          font-size: 18px;
        }

        @media (max-width: 1600px) {
          font-size: 1vw;
        }

        @media (max-width: 375px) {

          &.nav-call,
          &.nav-mail {
            font-size: 11px;
          }
        }

        span {
          color: $red;
          text-transform: uppercase;

          &:hover {
            color: $black;
          }
        }

        @media (max-width:1024px) {
          font-size: 14px;
        }

        @media (max-width:991px) {
          padding: 15px 0;
          margin-left: 15px;

          &:first-child {
            margin-left: 0;
          }
        }


        &.dropdown-toggle {
          span {
            color: $red !important;
            text-transform: uppercase;

            @media (max-width: 767px) {
              img {
                width: 32px;
                height: 32px;
              }
            }
          }
        }

        &:hover {
          color: $red;

          span {
            color: $black !important;
          }
        }

        &.has-submenu {
          position: relative;

          @media (max-width:1024px) {
            padding-right: 0;
          }

          .arrow-up {
            display: none;
            height: 15px;
            width: 15px;
            position: absolute;
            top: 23px;
            right: 0;
            transform: rotate(180deg);

            @media (max-width:991px) {
              display: block;
            }
          }
        }

        &.search-icon {
          &::after {
            content: "";
            background-image: url('../../__img/newlayout/white-search-icon.png');
            // position: relative;
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            background-size: 100%;
            background-position: center;
          }
        }

        &.openedSubmenu {
          .arrow-up {
            transition: all 0.2s ease-in-out;
            transform: rotate(360deg);
          }
        }
      }

      &:hover {
        .nav-item {
          span {
            color: $white;
          }
        }
      }

      .sub-menu {
        &.opened {
          display: block !important;

        }
      }

      &.search {
        padding-left: 10px;
        padding: 16px;
        position: relative;
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;

        @media (max-width:991px) {
          display: none;
        }


        .search-black {
          display: none;
        }

        .nav-link {
          height: 30px;
          width: 30px;
          display: inline-block;

        }

        .wrapper-simple {
          position: absolute;
          right: 55px;


          .search-white,
          .search-black {
            position: absolute;
            bottom: 8px;
            right: 8px;
            z-index: 9999;
          }

          input[type=text] {
            color: $black;
            background: $sub-hover;
            border-radius: 23px;
            padding: 10px 15px;
            border: 0;
            font-size: 16px;
            z-index: 99;
            width: 300px;
            display: none;
            transition: all .5s ease-in;
            transition: width 0.5s ease-in-out;


            &:focus {
              outline: none;
            }

            @media (max-width:1200px) {
              width: 180px;
            }
          }
        }
      }

      .sub-menu {
        display: none !important;
        background-color: $sub-hover;
        overflow-x: auto;
        position: absolute;
        z-index: 9;
        width: 100%;
        left: 0;
        text-align: left;
        padding-top: 65px;
        padding-bottom: 40px;

        .close-icon {
          right: 20px;
          top: 20px;
          font-size: 20px;
          position: absolute;

          &:hover {
            color: $red !important;
            cursor: pointer;
          }
        }

        @media(max-width: 991px) {
          top: 52px;
        }

        .solution-title {
          padding: 30px 0 60px 0;
          border-bottom: 3px solid $border-color;
          text-align: center;
          margin-bottom: 50px;

          h4 {
            display: inline-block;
            font-weight: lighter;
          }
        }

        .row {
          right: 0;

          .divider {
            position: relative;
            padding: 0 70px;

            &:last-child {
              &::after {
                display: none;
              }
            }

            &::after {
              position: absolute;
              right: 0;
              top: 0;
              height: 100%;
              width: 3px;
              border: 3px solid $border-color;
              content: " ";
            }
          }
        }


        .carte-benefits {
          position: relative;

          h6 {
            text-transform: uppercase;
            margin-bottom: 50px;
            letter-spacing: 2px;
            color: $black;
            font-size: 1.2vw;

            @media (max-width: 767px) {
              font-size: 14px;
            }
          }

          ul {
            list-style: none;

            li {
              font-size: 1.04vw;
            }
          }

          .solutions-head {
            padding: 0;

            li {
              margin-bottom: 26px;

              a {
                color: $hover-text-color;
                line-height: normal;
                font-weight: bold;

                &:hover {
                  color: $red;
                }

                @media (max-width:1190px) {
                  font-size: 12px;
                }


              }

              .arrow {
                display: inline-block;
                position: relative;
                width: 100%;
                border-bottom: 3px solid $border-color;

                &::after {
                  content: '';
                  background-image: url('/__img/newlayout/arrow.png');
                  height: 28px;
                  width: 28px;
                  display: inline-block;
                  background-repeat: no-repeat;
                  background-size: cover;
                  position: absolute;
                  bottom: 5px;
                  right: 0;
                }
              }
            }
          }
        }


        input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          border: 0;
          background: transparent;
          width: 100%;
          padding-right: 30px;

          &:hover {
            color: #636363;
          }

          &::-webkit-input-placeholder {
            font-size: 14px;
            font-weight: bold;

            @media (max-width:1190px) {
              font-size: 18px;
            }

          }

          &:focus {
            outline: none;
          }
        }

        &:hover {
          display: block;

          .close-icon {
            color: $black;
          }

          .carte-benefits,
          .solution-title {

            h6,
            h4 {
              color: $black;
            }
          }
        }


      }


      &.dropdown {
        // display: none;

        .dropdown-toggle {
          &::after {
            display: none;
          }

          .fa-angle-down {
            font-size: 20px;
          }
        }

        .dropdown-menu {
          background-color: $header-color;
          left: auto;
          right: -11px;
          border-radius: 0;
          top: 73px;
          border: 0;
          min-width: max-content;
          padding: 0;
          margin: 0;
          max-height: calc(100vh - 79px);
          overflow-x: auto;
          overflow: hidden;

          @media (max-width:1200px) {
            min-width: max-content;
            overflow: hidden;
          }

          @media (max-width:991px) {
            top: 60px;
            right: -65px;
            overflow: hidden;
          }

          @media (max-width: 767px) {
            min-width: 95vw;
            width: 100%;
            right: -34px;
            left: auto;
            margin: 0 auto;
            max-height: calc(100vh - 60px);
            overflow-x: auto;
          }

          .dropdown-wrapper {

            padding: 30px;

            @media (max-width:1200px) {
              padding: 30px 55px;
            }

            @media (max-width:767px) {
              padding: 30px 5px 10px;
            }

            .language-title {
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin-right: 20px;

              h6 {
                color: $black;
                margin-bottom: 25px;
                padding-left: 24px;


                a {
                  color: $black;
                  text-decoration: none;

                  &:hover {
                    color: $red;
                  }
                }

                &.active_lang {
                  a {
                    color: $red;
                  }
                }
              }

              &:nth-child(2n) {
                h6 {
                  margin-bottom: 11vw;

                  @media (max-width:1200px) {
                    margin-bottom: 7.6vw;
                  }
                }
              }
            }

            .word-title {
              margin-left: 70px;

              @media (max-width:767px) {
                margin-left: 90px;
              }

              h4 {
                color: $black;
                margin-bottom: 25px;
                padding-left: 24px;

                a {
                  color: $black;
                  text-decoration: none;

                  &:hover {
                    color: $red;
                  }
                }

                @media (max-width:767px) {
                  padding-left: 0;
                }
              }
            }

            .language-list {
              display: flex;

              .dropdown-language {

                .country-language {
                  padding: 0 20px;


                  @media (max-width: 767px) {
                    padding: 0 10px;
                  }

                  li {
                    margin-bottom: 6px;
                    list-style: none;

                    a {
                      font-size: 1.5vw;
                      color: $black;
                      text-transform: capitalize;
                      text-decoration: none;

                      @media (max-width:767px) {
                        font-size: 16px;
                        display: flex;
                      }

                      &:hover {
                        color: $red;
                      }

                      span {
                        border-radius: 50%;
                        padding-right: 14px;

                        @media (max-width:767px) {
                          height: 40px;
                          width: 40px;
                          display: inline-table;
                        }
                      }
                    }
                  }
                }
              }
            }


          }

        }

      }

      &.web-dropdown {
        padding: 15px 16px;


        .down-arrow {
          display: none;
        }
      }

    }

    .navbar-collapse {

      .site-header-nav {
        width: 100%;

        .navbar-nav {
          justify-content: space-between;
          height: 100%;

          .site-header-left {
            .head-call {
              padding: 16px 9px;

              @media (max-width: 991px) {
                padding: 0 12px;
              }

              a {
                font-size: 0.8vw;
                text-decoration: none;

                @media (max-width: 991px) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }


    }

    &:focus,
    &:hover {
      color: $black;
      background-color: $header-color;

      .navbar {
        .left-logo {
          img {
            display: none;
          }

          .img-black {
            display: block;
          }
        }

        .logo {
          img {
            display: none;
          }

          .img-black {
            display: block;
          }
        }
      }
    }

    .mobile-dropdown {
      margin: 0 0 0 auto;

      .dropdown {
        display: none;

        .down-arrow,
        .down-arrow-white {
          @media (max-width: 375px) {
            width: 15px;
            height: 10px;
          }
        }


        .dropdown-toggle {

          .cananda-img {
            width: 30px;
            height: 30px;
            display: inline-block;
          }

          .fa-angle-down {
            font-size: 20px;
          }

          .down-arrow {
            display: none;
          }
        }

        .dropdown-menu {
          background-color: $header-color;
          left: auto;
          border-radius: 0;
          top: 98%;
          border: 0;


        }

        @media (max-width:991px) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;

        }
      }
    }

  }

  .start-near {
    text-align: center;
    font-size: 1.6vw;
    font-weight: bold;
    width: 100%;
    margin-bottom: 55px;
    margin-top: 30px;

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        color: $red;
      }
    }

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  .submenu-separator {
    border-bottom: 1px solid gray;
    padding-bottom: 20px;

  }

  .dropdown-menu {
    background-color: $header-color;
    left: auto;
    border-radius: 0;
    top: 98%;
    border: 0;
  }

  .search-bar {
    display: none;

    @media (max-width: 991px) {
      display: block;

      input {
        width: 100%;
        border: none;
        border-radius: 8px;
        padding: 15px;
        margin: 10px 0;

        &:focus {
          outline: none;
        }

        @media (max-width: 425px) {
          border-radius: 5px;
          padding: 9px;
        }
      }

    }

  }

  .ui-autocomplete {

    li {
      a {
        font-size: 16px;
        text-decoration: none;
      }
    }

  }


  .navbar-inverse {


    .navbar-nav {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: 991px) {
        flex-direction: column;
        margin: 0;
      }

      li {
        a {
          @media (min-width: 992px) and (max-width: 1199px) {
            padding: 10px 5px;
          }
        }
      }

      .dropdown-menu {
        position: absolute;
        left: 0;
        width: auto;
        // right: 0;
        top: 50px;

        @media (max-width: 991px) {
          position: static;
          background-color: #CBCAD0;
        }

        li {
          a {
            margin-bottom: 10px;
            white-space: unset;

            @media (max-width: 991px) {
              color: #000 !important;

              span {
                color: #000 !important;
              }
            }
          }
        }
      }

      span {
        &.caret {
          display: none;
        }
      }
    }
  }

  #offres {
    .search_bar {
      .box {
        select {
          height: 34px;
        }
      }
    }
  }

  select.form-control:not([size]):not([multiple]) {
    height: 34px;
  }
}


.desg-main-wrapper {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  .information {
    margin-bottom: 10px;
    // padding: 0 5px;

    @media (max-width: 767px) {
      display: flex;
    }

    &:first-child {
      img {
        width: 80%;
        height: auto;

        @media (max-width: 767px) {
          margin: 0 auto;
          width: 150px;
          height: 150px;
        }

        @media (max-width:575px) {

          width: 91px;
          height: 91px;
        }
      }
    }

    img {
      margin-bottom: 10px;
      width: 100%;
      height: auto;


      @media (max-width: 767px) {
        margin: 0 auto;
        width: 150px;
        height: 46px;
      }

      @media (max-width:575px) {
        width: 91px;
        height: 38px;
      }
    }

  }
}


//schedual page scss //

select.form-control:not([size]):not([multiple]) {
  height: 34px;
}

.schedule-title-wrap {
  font-family: $gothic;

  .container {

    .row {
      align-items: baseline;

      .schedual-title-main-wrap {
        background-image: url("/__img/newlayout/Horaire-des-re-unions-FR.png");
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        height: 196px;
        display: flex;
        align-items: flex-end;

        .schedual-title-main {
          padding-left: 18%;
          padding-bottom: 30px;

          h4,
          h2 {
            font-weight: bold;
            margin: 0;
            word-break: break-word;
          }

          @media (max-width:1200px) {
            padding-left: 0;
          }

          @media (max-width:1024px) {
            padding-left: 12%;

            h4 {
              font-size: 16px;
              margin-bottom: 10px;
            }

            h2 {
              font-size: 20px !important;
            }
          }

          @media (max-width: 991px) {
            padding-left: 0;
            width: 60%;

            h2 {
              font-size: 18px !important;
            }
          }

          @media (max-width:768px) {
            width: 68%;
          }

          @media (max-width: 360px) {
            h2 {
              font-size: 16px !important;
            }

            h4 {
              font-size: 14px;
            }
          }

        }

        @media (max-width: 1024px) {
          height: 142px;
        }

        @media (max-width:768px) {
          height: 122px;
          background-size: contain;
          background-position: top;
        }
      }
    }
  }
}

.container {
  .country-schedule-form {
    ul {
      padding: 0;

      p {
        a {
          color: $red;
          text-decoration: none;
        }
      }
    }
  }
}


// all inner page anchor tag color changes
// .header {
//   a {
//     color: $white !important;

//     &:hover {
//       color: $red !important;
//     }
//   }
// }

// a {
//   color: $red !important;
// }

.breadcrumb {
  li {
    a {
      color: $red !important;
      font-family: "Helvetica Neue",
        Helvetica,
        Arial,
        sans-serif;
    }
  }
}

.forget-password {
  a {
    color: $red !important;
  }
}